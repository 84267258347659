const isValuePresent = (value: any): boolean => value != null

const isObjectLike = (item: any): boolean => item != null && typeof item === 'object' && !(item instanceof Date)

const stringifyValues = (obj: Record<string, any>): Record<string, string> => {
  const entries = Object.entries(obj).map(([key, value]) => [
    key,
    isObjectLike(value) ? stringifyValues(value) : value.toString(),
  ])

  return Object.fromEntries(entries)
}

// istanbul ignore next: remove in https://distribusion.atlassian.net/browse/OWL-3431
const array = {
  flatten: <T>(items: T[], getChildren: (item: T) => T[]): T[] => {
    return items.flatMap(item => [item, ...array.flatten(getChildren(item), getChildren)])
  },
  uniqueBy: <T>(items: T[], key: keyof T): T[] => [...new Map(items.map(item => [item[key], item])).values()],
  frequencyBy: <TValue, TKey>(items: TValue[], getKey: (item: TValue) => TKey): [TKey, number][] => {
    const frequencyMap = new Map<TKey, number>()
    items.forEach(item => {
      const key = getKey(item)
      const frequency = (frequencyMap.get(key) ?? 0) + 1
      frequencyMap.set(key, frequency)
    })

    return Array.from(frequencyMap.entries())
  },
  /**
   * Creates an array slice of specified length with desired item in the middle of the result array
   * @example
   * const items = [1, 2, 3, 4, 5, 6, 7, 8]
   * sliceMiddle([1, 2, 3, 4, 5, 6, 7, 8], 3, 5) // => [5, 6, 7]
   * sliceMiddle([1, 2, 3, 4, 5, 6, 7, 8], 4, 2) // => [1, 2, 3, 4]
   * sliceMiddle([1, 2, 3, 4, 5, 6, 7, 8], 5, 0) // => [1, 2, 3, 4, 5]
   * sliceMiddle([1, 2, 3, 4, 5, 6, 7, 8], 3, 7) // => [6, 7, 8]
   */
  sliceMiddle: <T>(items: T[], length: number, middleItemIndex: number): T[] => {
    const previousItemsCount = Math.floor(length / 2.0)
    const safeStartIndex = Math.max(middleItemIndex - previousItemsCount, 0)
    const sliceStartIndex = Math.min(safeStartIndex, items.length - length)
    const sliceEndIndex = sliceStartIndex + length

    return items.slice(sliceStartIndex, sliceEndIndex)
  },
  min: <T>(items: T[], getter: (item: T) => number): T | undefined =>
    items.reduce(
      (max, item) =>
        getter(item) < getter(max) ? /* istanbul ignore next: There is no use for this case yet */ item : max,
      items[0],
    ),
  sum: <T>(items: T[], getter: (item: T) => number): number => items.reduce((sum, item) => sum + getter(item), 0),
  compact: <T>(items: T[]): NonNullable<T>[] => items.filter(item => item != null) as NonNullable<T>[],
  containsOrDefault: <T>(value: T, array: T[], otherwise: T): T => (array.includes(value) ? value : otherwise),
  findBy: <TData extends object, TKey extends keyof TData>(array: TData[], prop: TKey, value: TData[TKey]) =>
    array.find(entry => entry[prop] === value),
  moveIndex: (array: any[], index: number, action: DirectionalAction): number => {
    switch (action) {
      case 'back':
        return index === 0 ? array.length - 1 : index - 1
      default:
        return index === array.length - 1 ? 0 : index + 1
    }
  },
  toggle: <T>(value: T, array: T[], key?: keyof T): T[] => {
    /* istanbul ignore else */
    if (key) {
      return array.some(item => item[key] === value[key])
        ? array.filter(item => item[key] !== value[key])
        : [...array, value]
    }
    /* istanbul ignore next: leave it here for possible future use */
    return array.includes(value) ? array.filter(item => item !== value) : [...array, value]
  },
  mapBy: <T>(data: T[], getKey: (item: T) => string): Map<string, T> => new Map(data.map(item => [getKey(item), item])),
  groupBy: <Data, Key extends string | number>(data: Data[], getKey: (item: Data) => Key): Record<Key, Data[]> =>
    data.reduce(
      (groups, item) => {
        const key = getKey(item)
        groups[key] = [...(groups[key] ?? []), item]
        return groups
      },
      {} as Record<Key, Data[]>,
    ),
}

const object = {
  compact: <Result = Object>(item: Object): Result => {
    const entries = Object.entries(item).filter(([_key, value]) => isValuePresent(value))

    return Object.fromEntries(entries) as Result
  },
  isSimilar: (a: Object, b: Object): boolean => {
    const formattedA = stringifyValues(a)
    const formattedB = stringifyValues(b)

    return object.isEqual(formattedA, formattedB)
  },
  isEqual: (a: Object, b: Object): boolean => {
    if (a === b) return true

    const keysA = Object.keys(a) as (keyof Object)[]
    const keysB = Object.keys(b) as (keyof Object)[]

    if (keysA.length !== keysB.length) return false

    for (const key of keysA) {
      const val1 = a[key]
      const val2 = b[key]

      const areObjects = isObjectLike(val1) && isObjectLike(val2)
      if ((areObjects && !object.isEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false
      }
    }

    return true
  },
  toArray: <K extends string, V, R>(obj: Record<K, V>, fn: (k: K, v: V) => R): R[] => {
    return Object.entries(obj).map(([k, v]): R => fn(k as K, v as V))
  },
  convertKeys: <T extends Record<string, any>>(params: Record<string, any>, callback: (key: string) => string): T =>
    Object.entries(params).reduce((acc, [key, _value]) => {
      let value = _value
      if (isObjectLike(_value)) value = object.convertKeys(_value, callback)
      if (Array.isArray(_value)) value = _value.map(data => object.convertKeys(data, callback))

      return { ...acc, [callback(key)]: value }
    }, {} as T),
  convertToSnakeCase: <T extends Record<string, any>>(params: Record<string, any>): T =>
    utils.object.convertKeys(params, key => utils.string.toKebabCase(key, '_')),
  convertToCamelCase: <T extends Record<string, any>>(params: Record<string, any>): T =>
    utils.object.convertKeys(params, key => utils.string.toCamelcase(key)),
  removeKeys: <T extends Object>(obj: T, keys: (keyof T)[]) => {
    const entries = Object.entries(obj).filter(([objKey]) => !keys.includes(objKey as keyof T))

    return Object.fromEntries(entries) as T
  },
  switchKeyValue: <T extends Record<string, any>>(obj: Record<string, any>): T =>
    Object.fromEntries(Object.entries(obj).map(arr => arr.reverse())),
}

const common = {
  hash: (object: any): string => JSON.stringify(object),
  times: <T>(times: number, fn: (index: number) => T): T[] =>
    Array(times)
      .fill(null)
      .map((_, index) => fn(index)),
}

const utils = {
  common,
  array,
  function: {
    isFunction: (arg: any): arg is Function => typeof arg === 'function',
    debounce: <T extends (...args: any[]) => void>(callback: T, delay: number): T => {
      let timeout: number | undefined

      return ((...args: any[]) => {
        clearTimeout(timeout)
        timeout = window.setTimeout(() => {
          callback(...args)
        }, delay)
      }) as T
    },
    toPromiseLike:
      (callback: () => Promise<void>): ((resolve: any, reject: any) => void) =>
      (resolve, reject): void => {
        void callback().then(resolve).catch(reject)
      },
  },
  object,
  string: {
    toKebabCase: (string: string, separator = '-'): string =>
      string
        .replace(/([a-z])([A-Z])/g, `$1${separator}$2`)
        .replace(/[\s_]+/g, separator)
        .toLowerCase(),
    toCamelcase: (string: string): string => {
      const parts = string.split('_')

      return [parts[0], ...parts.slice(1).map(utils.string.capitalize)].join('')
    },
    capitalize: (string: string): string => string.charAt(0).toUpperCase() + string.toLowerCase().slice(1),
    trim: (string: string, symbol: string): string => {
      const regex = new RegExp(`^\\${symbol}+|\\${symbol}+$`, 'g')

      return string.replace(regex, '')
    },
    isSimilar: (a: string, b: string): boolean => a.toLowerCase() === b.toLowerCase(),
    nextChar: (char: string): string => String.fromCharCode(char.charCodeAt(0) + 1),
    previousChar: (char: string): string => String.fromCharCode(char.charCodeAt(0) - 1),
  },
  number: {
    clamp: (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max),
  },
}

export default utils
