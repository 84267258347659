import { Form, FormikConfig, FormikProps, FormikProvider, useFormik } from 'formik'
import React, { ReactElement, ReactNode, RefObject, useEffect, useImperativeHandle, useState } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { MaxAttemptsError } from '@lib/polling'
import { CheckoutFormData, useInitialFormValues } from '@pages/Checkout/hooks/useInitialFormValues'
import PaymentErrorModal from '@pages/Checkout/Payment/ErrorModal'
import PaymentInProgressModal from '@pages/Checkout/Payment/InProgressModal'
import { useCheckout } from '@stores/checkout'

interface CheckoutFormProviderProps {
  canSubmit?: (data: CheckoutFormData) => boolean
  isRefund?: (data: CheckoutFormData) => boolean
  innerRef: RefObject<FormikProps<CheckoutFormData>>
  submit: (data: CheckoutFormData) => Promise<void> | void
  validate?: FormikConfig<CheckoutFormData>['validate']
  children: ReactNode
  isLoading: boolean
  error?: Error | null
}

const CheckoutFormProvider = (props: CheckoutFormProviderProps): ReactElement => {
  const { children, canSubmit, submit, innerRef, isLoading, error, isRefund, validate } = props
  const [warning, setWarning] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  const { t } = useTranslation()
  const [{ paymentInstance, outbound }] = useCheckout()
  const initialValues = useInitialFormValues()

  const onSubmit: FormikConfig<CheckoutFormData>['onSubmit'] = async (data): Promise<void> => {
    try {
      // istanbul ignore else: unreachable condition because now we hide the pay button if there is no price
      if ((isRefund?.(data) || data.paymentMethod) && (canSubmit?.(data) ?? true)) {
        setIsSubmitting(paymentInstance?.isSubmitting ?? true)
        setWarning(null)
        await submit(data)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const formik = useFormik<CheckoutFormData>({
    onSubmit,
    initialValues,
    validate,
  })
  useImperativeHandle(innerRef, () => formik)

  const isRequestLoading = !!(isLoading || paymentInstance?.isLoading) || isSubmitting
  const errorFinalized = error ?? paymentInstance?.error

  useEffect(() => {
    outbound && !formik.values.fareClass && formik.setFieldValue('fareClass', outbound.cheapestFareClassCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outbound])

  useEffect(() => {
    if (isRequestLoading) return
    if (!errorFinalized) return
    if (errorFinalized instanceof MaxAttemptsError) {
      setWarning(t('checkout.pixMaxAttemptsExceeded'))
      scrollTo({ top: 0 })
    } else {
      setShowErrorModal(true)
      formik.setSubmitting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFinalized, isRequestLoading, setWarning, t])
  const closeErrorModal = (): void => {
    setShowErrorModal(false)
  }
  const retryPayment = (): void => {
    formik.values.paymentMethod !== 'blik' && void formik.submitForm()
    closeErrorModal()
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <PaymentErrorModal
          opened={showErrorModal && !isRequestLoading}
          onClose={closeErrorModal}
          onRetry={retryPayment}
        />
        <PaymentInProgressModal opened={isRequestLoading} />
        {warning && <div className={bem('checkout-form', 'message')}>{warning}</div>}
        {children}
      </Form>
    </FormikProvider>
  )
}

export default CheckoutFormProvider
